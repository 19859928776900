import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Helmet } from "react-helmet";
import Blockquote from "src/components/Blockquote";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import Layout from "src/components/Layout";
import { Markdown } from "src/components/Markdown";

export default function Page() {
  return (
    <Layout>
      <Helmet>
        <title>Veteran Services</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle="Veteran Services"
        title="A Pathway to Housing for Veterans Experiencing Homelessness"
        maxTitleWidth={"18em"}
      />

      <Container>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Blockquote>
              EOCP recognizes the service and sacrifices that Veterans have
              given to our country, and strives to provide hope, dignity,
              respect, and opportunity to Veterans in need of support. EOCP
              offers a variety of programs for Veterans facing housing crises,
              including specialized assistance designed in partnership with the
              Department of Veterans Affairs.
            </Blockquote>
            <Box mb={4}>
              <Typography component="div">
                <Markdown>
                  {`
**Oakland Together for Vets**

EOCP's Oakland Together for Vets is funded through a grant from the **Supportive Services for Veteran Families (SSVF)** program at the VA.  This program is a collaboration between EOCP and a network of services and care for Veterans in Alameda County.  It includes supportive services to very low-income Veterans and their families who are unhoused or at risk of losing permanent housing. The program conducts outreach into the community to bring services directly to Veterans in need of assistance.

Upon enrollment into the program, eligible Veterans will be supported with services including:

-   Temporary financial assistance, including rental assistance
-   Housing search and placement
-   Wraparound case management
-   Health care navigation
-   Employment services
-   Legal services referral
-   HUD-VASH voucher administration

Please call (**510) 746-3633** or email **veterans@eocp.net** to contact our team and learn more information.  Our staff can rapidly help eligible Veterans through the enrollment process.

**Need other Veteran resources beyond EOCP?**

**Call the hotline in your area:**

-   Alameda County: 1-888-479-1926
-   Solano County: 1-855-862-1804
-   Contra Costa County: 1-925-957-6042
-   San Francisco County: 1-415-523-1454
-   Sacramento, San Joaquin, & Amador Counties: 1-833-255-4899

`}
                </Markdown>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}
